import React from 'react';

import ImageZuGast1 from '../../images/home/2012-rockwell.jpg';
import RbsPage from '../controls/RbsPage';
import { Col, Container, Row } from 'react-bootstrap';


const Fotos = (props) => {

  const onOpen2025Click = () => {
    window.open('https://lightroom.adobe.com/shares/d237579a4f324478b41fd78d75aeaae5', '_blank');
  }

  const onOpen2024Click = () => {
    window.open('https://lightroom.adobe.com/shares/def3b27d9b6c40908003cd8c4658bc2e', '_blank');
  }

  return (
    <RbsPage>

      <section id="Fotos" className="custom-section-grey scrollIntoViewFix">
        <Container>
          <Row className='pt-5 justify-content-center'>
            <Col className='text-end'>
              <h1 className="text-center pt-2 pb-5 mb-2">
                Fotos von den Fachpressetagen
              </h1>
            </Col>
          </Row>


          <Row>
            <Col className="col-12 col-lg-6 col-xl-4">
              <div className={`CardWrapper mx-auto mb-5`}
                style={{
                  backgroundImage: `url(https://lightroom.adobe.com/v2c/spaces/d237579a4f324478b41fd78d75aeaae5/assets/468d09ce0b8847aeb4d571042d5268af/revisions/1b7a68cec8984fd6a3558fe8f20b5f1c/renditions/acb815969a61ecc99872d9bf2a06ec82)`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPositionX: 'right',
                  backgroundPositionY: 'center',
                  cursor: 'pointer'
                }}
                onClick={onOpen2025Click}
              >
                <div className="CardFotoOverlay">
                  <h2>Fachpressetage 2025</h2>
                  <p>04. und 05. Februar 2025</p>
                </div>
              </div>
            </Col>

            <Col className="col-12 col-lg-6 col-xl-4">
              <div className={`CardWrapper mx-auto mb-5`}
                style={{
                  backgroundImage: `url(https://lightroom.adobe.com/v2c/spaces/def3b27d9b6c40908003cd8c4658bc2e/assets/bd834c4e471045c2ab994fcbf6ec154b/revisions/86f7cc50798f43f189e28487e74402d6/renditions/0835ef5822d4565fec27a774d3ce59fd)`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  cursor: 'pointer'
                }}
                onClick={onOpen2024Click}
              >
                <div className="CardFotoOverlay">
                  <h2>Fachpressetage 2024</h2>
                  <p>03. und 04. Februar 2024</p>
                </div>
              </div>
            </Col>

          </Row>
        </Container>
      </section>


    </RbsPage>
  )

}

export default Fotos;
